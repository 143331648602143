div {
    color: #ffffff
}

.title-link {
    color: #ffffff;
    text-decoration: underline;
    text-decoration-color: #79ED85;
}

.name-author {
    color: #ffffff;
    font-size: 50px;
    font-weight: normal;
    margin-top: 80px;
}

.lastname-author {
    font-weight: 200;
    color: #ffffff;
}

.blank {
    font-weight: normal;
    color: #79ED85;
}

.job-author {
    color: #79ED85;
    font-weight: 400;
}

.detail-author {
    font-size: 28px;
    margin-top: 10px;
    color: #ffffff;
    font-weight: 200;
}

.tools-author {
    color: #74EEFF;
}

.row-1 {
    margin-top: 80px;
    background-color: #2A2948;
    padding-top: 10px;
    padding-bottom: 20px;
    height: 100%;
}

.row-2 {
    margin-top: 35px;
    background-color: #2A2948;
    padding-top: 10px;
    padding-bottom: 20px;
    height: 100%;
}

.title-job {
    color: #79ED85;
    font-size: 25px;
}

.text-job {
    font-weight: 300;
    text-align: justify
}

.card-view {
    background-color: #2A2948 !important
}

.card-title {
    color: #79ED85;
    text-align: center;
    margin-bottom: .2rem !important;
}

.card-tab {
    padding: 20px !important;
}

.row-tab {
    margin-top: 60px;
}

.row-tab .col-md-4 {
    padding-top: 20px;
}

.row-tab {
    margin-top: 80px;
}

.about-you {
    font-weight: 300;
    margin-bottom: 5px;
}

.form-control {
    background-color: #818191 !important;
    border: 1px solid #818191 !important;
    color: #fff !important;
}


::placeholder {
    color: #fff !important;
    opacity: none;
}

.btn-secondary {
    background-color: #79ED85 !important;
    color: #2A2948 !important;
    padding: 4px 35px !important;
    border-radius: 8px !important;
    box-shadow: -5px 5px 25px -5px rgba(0, 0, 0, 0.445);
}

.warn-me {
    font-weight: 300;
    text-align: justify;
    margin-top: 40px;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.link-tab img {
    margin-top: 80px;
    margin-right: 20px;
    filter: drop-shadow(-1px 1px 3px #222222)
}

.link-tab a {
    margin-right: 10px;
}